<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue';
import Modal from '@component-library/components/Modal.vue';
import ButtonSpinner from '@component-library/components/ButtonSpinner.vue';
import {
  OfflineProject,
  OfflineSample,
  ProjectOfflineRequest,
} from '@component-library/offline-data';
import OfflineUploadProjectManagement from './OfflineUploadProjectManagement.vue';
import UploadStatus from './UploadStatus.vue';
import useSampleOfflineManager from '@/js/composables/useSampleOfflineManager';
import useSampleUploadManager from '@/js/composables/useSampleUploadManager';
import useProjectOfflineManager from '@/js/composables/useProjectOfflineManager';
import useAuth from '@component-library/composables/useAuth';
import { useOfflineStorageManagerStore } from '@/js/composables/useOfflineStorageManager';
import OfflineProjectListItem from './OfflineProjectListItem.vue';
import axios from 'axios';
import useOfflineDownload from '@/js/composables/useOfflineDownload';
import InputCheckbox from '@component-library/components/InputCheckbox.vue';
import useLayerModelStore from '@/js/stores/layer-model';
import useLegacyRootBus from '@component-library/composables/useLegacyRootBus';
import useLegacyStore from '@component-library/composables/useLegacyStore';

const auth = useAuth();

const emit = defineEmits<{
  (event: 'close'): void;
}>();

const root = useLegacyRootBus();

const layerModelStore = useLayerModelStore();
const legacyStore = useLegacyStore();

const keepProjectOffline = ref(false);

const offlineStorageManager = useOfflineStorageManagerStore();
const { loadProjects } = offlineStorageManager;

const { updateProjectOfflineRequestStatus } = useProjectOfflineManager(
  offlineStorageManager
);

type ProjectWithOfflineRequests = OfflineProject & {
  offline_requests: ProjectOfflineRequest[];
};

export type FailedSampleUpload = {
  sample: OfflineSample;
  error: any;
};

type OfflineUploadIssue = {
  invalidated_requests: ProjectOfflineRequest[];
  missing_offline_projects: any;
};

const isLoading = ref(false);

const isUploading = ref(false);
const isUploadFinished = ref(false);
const currentSampleCount = ref(0);
const failedSampleUploads = ref<FailedSampleUpload[]>([]);

const currentOfflineProjectId = ref<number | null>(null);
const selectedOfflineProjectId = ref<number | null>(null);
const projects = ref<ProjectWithOfflineRequests[]>([]);
const selectedSampleIds = ref<(number | string)[]>([]);
const offlineUploadIssue = ref<OfflineUploadIssue | null>(null);

const offlineProjects = computed(() => {
  return (offlineStorageManager.offlineProjects as any as OfflineProject[])
    .map((project) => ({
      ...project,
      mostRecentUpdate: project.offline_requests.reduce(
        (max, request) => (max > request.updated_at ? max : request.updated_at),
        '1970-01-01'
      ),
    }))
    .sort((a, b) => (a.mostRecentUpdate < b.mostRecentUpdate ? 1 : -1));
});

const currentOfflineProject = computed(() => {
  return currentOfflineProjectId.value
    ? offlineProjects.value.find(
        (p) => p.project_id === currentOfflineProjectId.value
      ) || null
    : null;
});

const close = () => {
  window.location.reload();
  emit('close');
};

const quickProjectImport = () => {
  if (selectedOfflineProjectId.value === null) {
    return;
  }

  currentOfflineProjectId.value = selectedOfflineProjectId.value;

  selectedSampleIds.value = currentOfflineProject.value!.samples.map(
    (sample) => sample.id
  );

  uploadOfflineData();
};

const advancedProjectImport = () => {
  if (selectedOfflineProjectId.value === null) {
    return;
  }

  currentOfflineProjectId.value = selectedOfflineProjectId.value;

  selectedSampleIds.value = [];
};

const uploadOfflineData = async () => {
  try {
    isUploading.value = true;

    const userId = auth.user().user_id;
    const samples = currentOfflineProject.value!.samples.filter(
      (sample) => sample.id && selectedSampleIds.value.includes(sample.id)
    );

    const { removeOfflineSample } = useSampleOfflineManager(
      offlineStorageManager,
      currentOfflineProject.value!
    );

    const { updateOnlineSample, bringSampleOnline } = useSampleUploadManager(
      currentOfflineProject.value!
    );

    const { adminStoreOfflineData } = useOfflineDownload(offlineStorageManager);
    await adminStoreOfflineData(userId);

    for (const sample of samples) {
      const isSampleCurrentUsers = sample.offline_user_id === userId;

      const isSampleUploadable =
        (isSampleCurrentUsers && sample.is_edited_offline) ||
        sample.is_created_offline;

      try {
        if (isSampleUploadable) {
          console.log('sync sample online with id: ' + sample.id);

          await updateOnlineSample(sample);
        }

        if (isSampleCurrentUsers) {
          await bringSampleOnline(sample.id);
        }

        await removeOfflineSample(sample);
      } catch (e) {
        failedSampleUploads.value.push({
          sample,
          error: e,
        });
      }

      currentSampleCount.value += 1;
    }

    if (currentOfflineProject.value!.samples.length === 0) {
      for (const offlineRequest of currentOfflineProject.value!
        .offline_requests) {
        await updateProjectOfflineRequestStatus(
          offlineRequest.id,
          'has_published'
        );
      }

      await deleteProjectFromOfflineStorage(
        currentOfflineProject.value!.project_id
      );

      close();
    }
  } catch (e) {
    throw e;
  } finally {
    isUploading.value = false;
    isUploadFinished.value = true;
  }
};

const deleteProjectFromOfflineStorage = async (id: number) => {
  if (!keepProjectOffline.value) {
    await offlineStorageManager.deleteOfflineProject(id);
  }

  projects.value.splice(
    projects.value.findIndex((p) => p.project_id === id),
    1
  );
};

const projectHasInvalidatedRequests = (project: OfflineProject) => {
  return (
    project.offline_requests.findIndex(
      (request) =>
        (offlineUploadIssue.value?.invalidated_requests || []).filter(
          (invalidedRequest) => {
            return invalidedRequest.id === request.id;
          }
        ).length > 0
    ) !== -1
  );
};

const loadOfflineProjects = async () => {
  try {
    isLoading.value = true;

    await loadProjects();

    if (offlineProjects.value.length > 0) {
      selectedOfflineProjectId.value = offlineProjects.value[0].project_id;
    }

    const { data } = await axios.get('/api/offline/upload/issues', {
      params: {
        offlineRequestIds: offlineProjects.value
          .map((p) => p.offline_requests)
          .flat()
          .map((r) => r.id),
        offlineProjectIds: offlineProjects.value.map((p) => p.project_id),
      },
    });

    offlineUploadIssue.value = data;
  } catch (e) {
    throw e;
  } finally {
    isLoading.value = false;
  }
};

const reset = () => {
  isUploading.value = false;
  isUploadFinished.value = false;
  currentOfflineProjectId.value = null;
  selectedSampleIds.value = [];
  currentSampleCount.value = 0;
  failedSampleUploads.value = [];
  keepProjectOffline.value = false;

  loadOfflineProjects();
};

const showKeepProjectOfflineCheckbox = computed(() => {
  return (
    !currentOfflineProject.value ||
    currentOfflineProject.value.samples.length ===
      selectedSampleIds.value.length
  );
});

const isStartSyncDisabled = computed(() => {
  return !!(
    selectedOfflineProjectId.value === null ||
    (currentOfflineProject.value &&
      currentOfflineProject.value.samples.length === 0)
  );
});

onMounted(async () => {
  await loadOfflineProjects();
});
</script>

<template>
  <Modal
    :show="true"
    :half="true"
    @close="close"
    :loading="isLoading"
    :stretch="true"
    class="offline-manager"
  >
    <template v-slot:header>
      <span>How would you like to manage your offline projects?</span>
    </template>
    <template>
      <UploadStatus
        v-if="(isUploading || isUploadFinished) && currentOfflineProject"
        :project="currentOfflineProject"
        :currentSample="currentSampleCount"
        :totalSamples="selectedSampleIds.length"
        :failedSampleUploads="failedSampleUploads"
        :isUploading="isUploading"
        :isFinished="isUploadFinished"
      />
      <template v-else>
        <template v-if="!currentOfflineProject">
          <div class="list-group custom-scrollbar border-none">
            <OfflineProjectListItem
              v-for="(project, projectIndex) in offlineProjects"
              :key="`offline-upload-${projectIndex}`"
              :user="auth.user()"
              :project="project"
              :selectedOfflineProjectId="selectedOfflineProjectId"
              :hasInvalidatedRequests="projectHasInvalidatedRequests(project)"
              :class="{
                'pb-3': projectIndex !== offlineProjects.length - 1,
              }"
              @click.prevent.native="
                selectedOfflineProjectId = project.project_id
              "
            />

            <template v-if="offlineUploadIssue">
              <OfflineProjectListItem
                v-for="(
                  project, projectIndex
                ) in offlineUploadIssue.missing_offline_projects"
                :key="`missing-upload-${projectIndex}`"
                :user="auth.user()"
                :selectedOfflineProjectId="selectedOfflineProjectId"
                :project="project"
                :isMissingLocally="true"
                :hasInvalidatedRequests="false"
              />
            </template>
          </div>
        </template>

        <OfflineUploadProjectManagement
          v-else
          :project="currentOfflineProject"
          :selectedSampleIds="selectedSampleIds"
          @setSelectedSampleIds="selectedSampleIds = $event"
        />
      </template>
    </template>

    <template #footer>
      <template v-if="offlineProjects.length > 0">
        <div
          v-if="
            showKeepProjectOfflineCheckbox && !isUploading && !isUploadFinished
          "
          class="w-100 mb-3 fw-medium"
        >
          <InputCheckbox
            name="keepProjectOffline"
            v-model="keepProjectOffline"
            label="Would you like to keep project offline, to add additional samples later?"
          />
        </div>

        <div v-if="currentOfflineProject" class="w-100">
          <ButtonSpinner
            v-if="!isUploading && !isUploadFinished"
            type="button"
            class="btn btn-primary w-100 py-3 mb-2"
            :is-loading="isUploading"
            :disabled="selectedSampleIds.length === 0"
            @click.prevent.native="uploadOfflineData"
          >
            Upload Offline Data
          </ButtonSpinner>
          <button class="btn btn-flat w-100 py-2" @click="reset">
            <i class="fal fa-arrow-left"></i> Back
          </button>
        </div>
        <div v-else class="d-flex flex-column flex-md-row w-100 gap-2">
          <div class="w-100 mb-2 mb-md-0">
            <button
              class="btn btn-primary d-flex flex-column align-items-center py-2 w-100"
              :disabled="isStartSyncDisabled"
              @click="quickProjectImport"
            >
              <h3
                class="mb-1 fa-fw fal fa-tachometer-fastest d-block text-center opacity-75"
              />
              <span class="fw-medium">Quick Sync</span>
              <small class="fw-normal">
                <span class="fw-medium">All finished?</span> Bring everything
                back online
              </small>
            </button>
          </div>

          <div class="w-100">
            <button
              class="btn btn-flat d-flex flex-column align-items-center py-2 w-100"
              :disabled="isStartSyncDisabled"
              @click="advancedProjectImport"
            >
              <h3
                class="mb-1 fa-fw fal fa-cog d-block text-center opacity-75"
              />
              <span class="fw-medium">Advanced Sync</span>
              <small class="fw-normal">
                <span class="fw-medium">Not done?</span> Bring specific
                locations back online
              </small>
            </button>
          </div>
        </div>
      </template>
    </template>
  </Modal>
</template>

<style scoped>
.project-list-group > div {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 1em;
  margin-bottom: 1em;
}

.project-list-group > div:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}
</style>
