<script setup lang="ts">
import { defineAsyncComponent, ref } from 'vue';
import Modal from '@/js/components/Modal.vue';
import _uniq from 'lodash/uniq';

const ExcelWorksheetReader = defineAsyncComponent(
  () => import('../../ExcelWorksheetReader.vue')
);

const emit = defineEmits(['close', 'import']);

const ewr = ref();

function checkIsSelectedColumn(index: number) {
  return ewr.value!.selectedColumnIndex === index;
}

function handleColumnClick(index: number) {
  ewr.value!.selectColumn(index);
}

function handleClose() {
  emit('close');
}

function handleImport() {
  const { dataRows, selectedColumnIndex } = ewr.value!;

  if (!dataRows.length || selectedColumnIndex === null) {
    return;
  }

  emit(
    'import',
    _uniq(
      ewr.value!.columns[selectedColumnIndex].filter(
        (item, index) => index > 0 && item.trim()
      )
    )
  );
}
</script>

<template>
  <Modal
    class="import-excel-column-modal"
    :class="{
      'import-excel-column-modal-with-rows': ewr?.dataRows.length,
    }"
    :show="true"
    :half="true"
    @close="handleClose"
  >
    <template #header>Import a column of an Excel file</template>
    <ExcelWorksheetReader
      ref="ewr"
      class="flex-grow-1 d-flex flex-column p-2"
      :style="{ 'min-height': '0px', overflow: 'auto' }"
    >
      <template #worksheetContainer>
        <div
          class="flex-grow-1 d-flex flex-column mb-2"
          :style="{ 'min-height': '0px', overflow: 'auto' }"
        >
          <label class="form-label">Columns</label>
          <div class="flex-grow-1 table-container">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th
                    v-for="(header, hIndex) in ewr?.headerRow"
                    :key="`header-${hIndex}`"
                    class="text-center header"
                    :class="{
                      'bg-primary': checkIsSelectedColumn(hIndex),
                      'bg-secondary': !checkIsSelectedColumn(hIndex),
                    }"
                    scope="col"
                    @click="handleColumnClick(hIndex)"
                  >
                    {{ header }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(dr, drIndex) in ewr?.dataRows"
                  :key="`dataRow-${drIndex}`"
                >
                  <td
                    v-for="(option, oIndex) in dr"
                    :key="`option-${oIndex}`"
                    :class="{
                      'bg-primary': checkIsSelectedColumn(oIndex),
                    }"
                    :style="
                      checkIsSelectedColumn(oIndex)
                        ? {
                            '--bs-bg-opacity': 0.2,
                          }
                        : {}
                    "
                    @click="handleColumnClick(oIndex)"
                  >
                    {{ option }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <button class="btn btn-secondary me-2" @click="handleClose">
            Cancel
          </button>
          <button
            class="btn btn-primary"
            :disabled="ewr?.selectedColumnIndex === null"
            @click="handleImport"
          >
            Import
          </button>
        </div>
      </template>
    </ExcelWorksheetReader>
  </Modal>
</template>

<style lang="scss" scoped>
.import-excel-column-modal {
  .table-container {
    min-height: 0px;
    overflow: auto;
    cursor: pointer;

    .header {
      position: sticky;
      top: 0;
    }
  }

  &-with-rows {
    :deep(.modal-content) {
      max-height: 80%;
    }
  }
}
</style>
