import VueRouter from 'vue-router';
import store from './store';
import ProjectLayout from '@/js/layouts/Project.vue';
import FullScreenLayout from '@/js/layouts/FullScreen.vue';
import NotFound from '@/js/pages/404.vue';
import ListModule from '@/js/modules/project/list/index.vue';
import MapModule from '@/js/modules/project/map/index.vue';
import TemplateEditorModule from '@/js/pages/TemplateEditorPage.vue';
import ChainOfCustodyModule from '@/js/modules/project/chain-of-custody/index.vue';
import VersionControlModule from '@/js/modules/project/version-control/index.vue';
import AppManagerModule from '@/js/modules/project/app-manager/index.vue';
import HandoverModule from '@/js/pages/Handover.vue';
import { DATANEST_URL } from '@component-library/env';
import { useProjectStore } from '@component-library/store/project';
import auth from '@component-library/auth';

const router = new VueRouter({
  routes: [
    {
      path: '/',
      redirect: '/projects',
    },
    {
      path: '/login',
      beforeEnter(to, from, next) {
        window.location.replace(DATANEST_URL + '/projects');
        return false;
      },
    },
    {
      path: '/:project_id/map',
      component: FullScreenLayout,
      meta: {
        auth: true,
        projectRequired: true,
      },
      children: [
        {
          path: '/',
          name: 'project_map',
          component: MapModule,
          meta: {
            title: 'Map',
          },
        },
      ],
    },
    {
      path: '/project',
      component: ProjectLayout,
      meta: {
        auth: true,
      },
      children: [
        {
          path: '/projects',
          name: 'list',
          component: ListModule,
          meta: {
            title: 'Projects',
          },
        },
      ],
    },
    {
      path: '/template/:project_id/editor/:tab_id?',
      component: TemplateEditorModule,
      name: 'template_editor',
      meta: {
        title: 'App Editor',
        auth: true,
      },
    },
    {
      path: '/template/:project_id/apps',
      component: AppManagerModule,
      name: 'app_manager',
      meta: {
        title: 'App Manager',
        auth: true,
        projectRequired: true,
      },
    },
    {
      path: '/:project_id/chain-of-custody',
      component: ChainOfCustodyModule,
      name: 'coc_editor',
      meta: {
        title: 'Chain of Custody',
        auth: true,
        projectRequired: true,
      },
    },
    {
      path: '/:project_id/version-control/:sample_id',
      component: VersionControlModule,
      meta: {
        title: 'Version Control',
        auth: true,
        projectRequired: true,
      },
    },
    {
      path: '/handover/:access_token/:page?',
      component: HandoverModule,
    },
    {
      path: '/404',
      component: NotFound,
      meta: {
        title: 'Page Not Found',
      },
    },
    {
      path: '*',
      redirect: '/404',
    },
  ],
});

const waitForStorageToBeReady = async (to, from, next) => {
  await store.restored;

  const toRouteMeta = to.matched[0].meta;

  if (to.name == 'list' && navigator.onLine) {
    if (!auth.check()) {
      return next('/login');
    }

    try {
      await axios.get('/api/handover/datanest').then((response) => {
        window.location.href = response.data.redirect_url + '/project_pathway';
      });
      return;
    } catch (err) {
      if (err.response.status == 401) {
        return next('/login');
      }
    }
  }

  if (!toRouteMeta.projectRequired) {
    return next();
  }

  const project = store.getters.get_project_data;
  if (project) {
    return next();
  }

  axios
    .get('/api/project/details', {
      params: {
        project_id: to.params.project_id,
      },
    })
    .then((response) => {
      const projectStore = useProjectStore();
      if (
        projectStore.currentProject &&
        projectStore.currentProject.id === response.data.project.id
      ) {
        projectStore.resetProject();
      }
      projectStore.updateProject(response.data.project);
      return next();
    })
    .catch((error) => {
      console.warn(
        'Failed to load project from store, redirecting to project list.'
      );
      console.error(error);

      store.dispatch('resetProject');
      return next('/projects');
    });
};

router.beforeEach(waitForStorageToBeReady);

export default router;
