import {
  LoadChemicalsResponse,
  LoadExceedancesResponse,
  LoadSubFoldersResponse,
} from './useMapsApi';
import { getJSON as getJSONByFetch } from '../helpers/fetch-api';
import { getBaseUrl } from '@component-library/api';
import axios from 'axios';

export default function useFetchMapsApi(projectId: number, useAxios = true) {
  const getJSON = async (
    url: string,
    {
      params = {},
      headers = {},
    }: {
      params?: Record<string, any>;
      headers?: Record<string, any>;
    } = {}
  ) => {
    if (useAxios) {
      return await axios.get(url, { params });
    } else {
      return await getJSONByFetch(url, { params, headers });
    }
  };

  const loadFiguresRaw = async (): Promise<any> => {
    const {
      data: {
        figures,
        figure_styling_rules,
        template_tab_styling_rules,
        last_figure_id,
      },
    } = await getJSON(`${getBaseUrl()}/figure8/figure/list`, {
      params: { project_id: projectId },
    });

    return {
      figures,
      figure_styling_rules,
      template_tab_styling_rules,
      last_figure_id,
    };
  };

  const loadLayerModelsRaw = async (figureId: number): Promise<any> => {
    const {
      data: { layers, poly_gather_samples },
    } = await getJSON(`${getBaseUrl()}/figure8/layer/list`, {
      params: { project_id: projectId, figure_id: figureId },
    });

    return {
      layers,
      poly_gather_samples,
    };
  };

  const loadSubFolders = async (): Promise<LoadSubFoldersResponse> => {
    const { data } = await getJSON(
      `${getBaseUrl()}/figure8/figure/sub-folders`,
      {
        params: { project_id: projectId },
      }
    );

    return data;
  };

  const loadScenariosRaw = async (): Promise<any> => {
    const {
      data: { scenarios, styles, scenario_set },
    } = await getJSON(`${getBaseUrl()}/dashboard/scenario/get-styling`, {
      params: { project_id: projectId },
    });

    return {
      scenarios,
      styles,
      scenario_set,
    };
  };

  const loadChemicals = async (): Promise<LoadChemicalsResponse> => {
    const {
      data: { chemicals },
    } = await getJSON(`${getBaseUrl()}/figure8/data/chemicals`, {
      params: { project_id: projectId },
    });

    return {
      chemicals,
    };
  };

  const loadExceedances = async (): Promise<LoadExceedancesResponse> => {
    const {
      data: { exceedances },
    } = await getJSON(`${getBaseUrl()}/figure8/data/exceedances`, {
      params: { project_id: projectId },
    });

    return {
      exceedances,
    };
  };

  const loadChemicalResultsRaw = async (
    chemicalIds: number[]
  ): Promise<any> => {
    const {
      data: { chemicals },
    } = await getJSON(`${getBaseUrl()}/figure8/data/chemical-results`, {
      params: {
        project_id: projectId,
        chemical_ids: chemicalIds,
      },
    });

    return {
      chemicals,
    };
  };

  return {
    loadFiguresRaw,
    loadLayerModelsRaw,
    loadScenariosRaw,
    loadChemicals,
    loadExceedances,
    loadChemicalResultsRaw,
    loadSubFolders,
  };
}
