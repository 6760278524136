import axios from 'axios';
import { useNavigationStore } from '../store/navigation';
import { useProjectStore } from '../store/project';
import { useRouter } from 'vue-router/composables';
import useIsGather from '../composables/useIsGather';

function openUrl(url: string, isMiddleClick: boolean = false) {
  if (isMiddleClick) {
    window.open(url);
  } else {
    window.location.href = url;
  }
}

function appendQueryParam(url: string, param: string, value: string) {
  const separator = url.includes('?') ? '&' : '?';
  return `${url}${separator}${param}=${value}`;
}

export async function goToApp({
  isGather = undefined,
  route,
  app,
  isMiddleClick = false,
  $router,
  $store,
  project,
}: {
  isGather?: boolean;
  route: string;
  app: string;
  isMiddleClick?: boolean;
  $router: any;
  $store: any;
  project?: { template_id?: number; project_id?: number | null };
}) {
  let project_id: number | null = null;
  if (project) {
    project_id = project.project_id ?? null;
  }

  if (isGather === undefined) {
    isGather = useIsGather();
  }

  useProjectStore().showSwitcherModal = false;

  if (app == 'gather') {
    if (!project) {
      return;
    }
    const { template_id } = project;
    if (!isGather) {
      axios.get('/handover/gather').then((response) => {
        let url = response.data.redirect_url + (route ? `/${route}` : '');
        const navigationStore = useNavigationStore();
        if (navigationStore.demoModeEnabled) {
          url = appendQueryParam(url, 'demo', '1');
        }
        openUrl(url, isMiddleClick);
      });
      return;
    }

    if (isMiddleClick) {
      let props = $router.resolve({
        name: route,
        params: { project_id, template_id },
      });

      window.open(props.href);
      return;
    }

    $router.push({
      name: route,
      params: { project_id, template_id },
    });
    return;
  }

  if (app == 'hub') {
    if (isGather) {
      axios.get('/api/handover/datanest').then((response) => {
        let url = response.data.redirect_url + (route ? `/${route}` : '');
        const navigationStore = useNavigationStore();
        if (navigationStore.demoModeEnabled) {
          url = appendQueryParam(url, 'demo', '1');
        }
        openUrl(url, isMiddleClick);
      });
      return;
    }

    if (route == 'project_create') {
      project_id = null;
      await $store.dispatch('resetProject');
    } else if (isMiddleClick) {
      let props = $router.resolve({
        name: route,
      });

      window.open(props.href);
      return;
    }

    const currentRoute = $router.currentRoute;
    if (currentRoute.name === route) {
      return;
    }

    if (route.startsWith('/')) {
      $router.push(route);
      return;
    }

    $router.push({
      name: route,
      params: { project_id },
    });
  }
}
