<script setup lang="ts">
import { computed } from 'vue';
import AdvancedAppList from './AdvancedAppList.vue';
import InputSelectFloating from '../InputSelectFloating.vue';
import GeneralSettingSelector from './GeneralSettingSelector.vue';
import type { Project } from '../../project';

//refactor to component-library
import { getBasemapApis } from '@maps/business-logic/basemap';
import { Figure } from '../../maps';
import { KeyValue } from '../../key-value';

const props = defineProps<{
  selectedProject: Project;
  figures: Figure[];
  userId: number;
  userDevice?: string | null;
  figureId?: number | null;
  basemap: number;
  radius: number;
  selectedAppIds: number[];
  selectedSampleIds: number[];
}>();

const basemaps = getBasemapApis(
  props.selectedProject.address_country,
  props.selectedProject.address_state ?? undefined
).filter((b) => b.index === 0);

const emit = defineEmits<{
  (e: 'start'): void;
  (e: 'back'): void;
  (e: 'update:offlineRequest', keyValue: KeyValue): void;
  (e: 'toggleEnabledPushNotifications', value: boolean): void;
}>();

const userId = computed({
  get() {
    return props.userId;
  },
  set(value) {
    emit('update:offlineRequest', { key: 'user_id', value });
  },
});

const userDevice = computed({
  get() {
    return props.userDevice;
  },
  set(value) {
    emit('update:offlineRequest', { key: 'device', value });
  },
});

const figureId = computed({
  get() {
    return props.figureId;
  },
  set(value) {
    emit('update:offlineRequest', { key: 'figure_id', value });
  },
});

const basemap = computed({
  get() {
    return props.basemap;
  },
  set(value) {
    emit('update:offlineRequest', { key: 'basemap', value });
  },
});

const radius = computed({
  get() {
    return props.radius;
  },
  set(value) {
    emit('update:offlineRequest', { key: 'radius', value });
  },
});
</script>
<template>
  <Fragment>
    <GeneralSettingSelector
      v-if="figureId"
      :projectId="selectedProject.project_id"
      :userId="userId"
      @selectUserId="(id) => (userId = id)"
      :userDevice="userDevice"
      @selectUserDevice="(device) => (userDevice = device)"
      :figures="figures"
      :figureId="figureId"
      @selectFigureId="(id) => (figureId = id)"
      @toggleEnabledPushNotifications="
        emit('toggleEnabledPushNotifications', $event)
      "
    />

    <InputSelectFloating
      required
      class="mb-3"
      name="mode"
      label="Basemap"
      v-model="basemap"
    >
      <option
        v-for="(basemap, basemapIndex) in basemaps"
        :value="basemap.index"
        :key="`basemap-${basemapIndex}`"
      >
        {{ basemap.title }}
      </option>
    </InputSelectFloating>

    <div class="mb-2">
      <label for="radius" class="form-label">
        Radius of tiles around address
        <span class="fw-medium"> ({{ radius }}m) </span>
      </label>
      <input
        type="range"
        class="form-range"
        min="1"
        max="500"
        id="radius"
        v-model.number="radius"
      />
    </div>

    <span class="fw-medium d-block mb-2"> Apps to take offline </span>
    <AdvancedAppList
      :project="selectedProject"
      :selectedAppIds="selectedAppIds"
      :selectedSampleIds="selectedSampleIds"
      @setSelectedAppIds="
        ($event) =>
          emit('update:offlineRequest', { key: 'app_ids', value: $event })
      "
      @setSelectedSampleIds="
        ($event) =>
          emit('update:offlineRequest', { key: 'sample_ids', value: $event })
      "
      class="mb-2"
    />
  </Fragment>
</template>
