import './bootstrap';
import Vue from 'vue';
import VueRouter from 'vue-router';
import { captureException } from '@sentry/browser';
import ClickOutside from '@component-library/directives/click-outside';
import { isEnvTruthy } from '@component-library/utils';
import router from '@/js/routes';
import store from '@/js/store';
import { createPinia, PiniaVuePlugin } from 'pinia';
import { VTooltip } from 'v-tooltip';
import App from '@/js/layouts/App.vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import hosts from '@maps/lib/olbm/layer/basemap/config/hosts';
import '@component-library/EventBus';
import createSentry from '@component-library/sentry';
import ToastsStorePlugin from '@component-library/store/plugins/toasts-vue-adapter-plugin';
import { initializeAnalytics } from '@component-library/analytics';
import { useOfflineStorageManagerStore } from '@/js/composables/useOfflineStorageManager';
import { Fragment } from 'vue-frag';
import { DATANEST_URL } from '@component-library/env';

// If explicitly enabled or not local environment
// Use the service worker.
if (
  isEnvTruthy('VITE_SERVICE_WORKER') ||
  import.meta.env.VITE_APP_ENV !== 'local'
) {
  import('./register-service-worker');
}

//Set Vue router
// @ts-expect-error
Vue.router = router;

//Set Axios configuration
Vue.use(VueAxios, axios);
axios.defaults.baseURL = `/`;

//Register offline axios interceptors
import './offline-interceptor';
import { useToastStore } from '@component-library/store/toasts';
import auth from '@component-library/auth';

Vue.component('Fragment', Fragment);

// Tooltips
// @ts-expect-error
VTooltip.options.defaultBoundariesElement = 'window';
// @ts-expect-error
VTooltip.options.autoHide = false;
Vue.directive('tooltip', VTooltip);

Vue.directive('click-outside', ClickOutside);

Vue.use(PiniaVuePlugin);

const pinia = createPinia();

// @ts-ignore
Vue.use(pinia);

const basemapApiHosts = Object.values(hosts);

// Add project ID to requests
axios.interceptors.request.use(
  function (config) {
    if (!config.params) {
      config.params = {};
    }

    if (config.url?.includes('.s3.')) {
      if (config.headers) {
        delete config.headers['Authorization'];
      }
      return config;
    }

    if (
      basemapApiHosts.some((href) => config.url?.includes(href)) ||
      config.url?.match(/\/markers\/\d+\?/)
    ) {
      return config;
    }

    const isExternalUrl =
      !!config.url?.match(/^http(s)?/) && !config.url.includes(import.meta.env.VITE_APP_URL);
    if (!config.params.project_id && !isExternalUrl) {
      config.params.project_id =
        store.getters.get_project_field_by_key('project_id');
    }

    if (config.url?.includes('/safety/')) {
      config.url = `/api${config.url}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

//Intercept errors if project, or user session expired.
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (!error.response) {
      captureException(error);
      return Promise.reject(error);
    }

    const status = error.response.status,
      errorMessage = error.response.data.message;

    if (status !== 422) {
      captureException(error);
    }

    if (
      status == 401 &&
      !error.request.responseURL.includes(import.meta.env.VITE_APP_URL)
    ) {
      return true;
    }

    if (status == 401) {
      console.error('Clearing login as we received an 401 error status');
      if (auth.check()) {

        auth.logout();
      }
      useToastStore().error('Your session has expired.');
      window.location.replace(DATANEST_URL);
    }

    if (
      status == 403 &&
      (errorMessage == 'Project number has not been set.' ||
        errorMessage == 'You do not have access to this project.')
    ) {
      useToastStore().error(errorMessage);
      window.location.replace(DATANEST_URL);
    }

    if (status == 413) {
      useToastStore().error('The uploaded file size exceeded our upload limit.');
    }

    if (status === 419) {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      useToastStore().warning('Session expired, refreshing page...');
    }

    return Promise.reject(error);
  }
);

// Can force offline mode, with-out having to install service worker
if (isEnvTruthy('VITE_FORCE_OFFLINE_MODE')) {
  Object.defineProperty(window.navigator, 'onLine', {
    get: () => false,
  });
}

Vue.use(VueRouter);

if (
  import.meta.env.VITE_SENTRY_LARAVEL_DSN &&
  import.meta.env.VITE_SENTRY_LARAVEL_DSN != ''
) {
  createSentry();
}

(async () => {
  const offlineStorageManager = useOfflineStorageManagerStore();
  // @ts-expect-error this appears to require `.value` but stores shouldnt required this
  if (offlineStorageManager.offlineProjects.length === 0) {
    await offlineStorageManager.loadProjects();
  }

  new Vue({
    el: '#app',
    router,
    store,
    pinia,
    render: (h) => h(App),
  });
  Vue.use(ToastsStorePlugin);

  initializeAnalytics();
})();
