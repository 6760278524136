import { Ref, ref } from 'vue';
import { OfflineProject, OfflineSample } from '@component-library/offline-data';
import { defineStore } from 'pinia';
import {
  OfflineStorageManagerReturnTypeSW,
  UpdateSampleRequest,
  useOfflineStorageManagerStoreSW,
} from './useOfflineStorageManagerSW';

export interface OfflineStorageManagerReturnType
  extends OfflineStorageManagerReturnTypeSW {
  isLoading?: Ref<boolean>;
  isDownloading?: Ref<boolean>;
}

export const useOfflineStorageManagerStore = defineStore(
  'offlineStorageManager',
  (): OfflineStorageManagerReturnType => {
    const offlineLocalForageStorageManager = useOfflineStorageManagerStoreSW();

    const isLoading = ref(false);
    const isDownloading = ref(false);
    const offlineProjects = ref<OfflineProject[]>([]);

    const loadProjects = async () => {
      isLoading.value = true;

      await offlineLocalForageStorageManager.loadProjects();
      offlineProjects.value =
        offlineLocalForageStorageManager.offlineProjects.value;

      isLoading.value = false;
    };

    const getProjectById = (id: number) => {
      return offlineLocalForageStorageManager.getProjectById(id);
    };

    const setProjects = async (projects: OfflineProject[]) => {
      await offlineLocalForageStorageManager.setProjects(projects);
      await loadProjects();
    };

    const createOfflineProject = async (project: OfflineProject) => {
      await offlineLocalForageStorageManager.createOfflineProject(project);
      await loadProjects();
    };

    const deleteOfflineProject = async (id: number) => {
      await offlineLocalForageStorageManager.deleteOfflineProject(id);
      await loadProjects();
    };

    const updateOfflineProject = async (
      id: number,
      data: Partial<OfflineProject>
    ) => {
      await offlineLocalForageStorageManager.updateOfflineProject(id, data);
      await loadProjects();
    };

    const createOfflineSample = async (
      sample: OfflineSample,
      project_id: number
    ) => {
      await offlineLocalForageStorageManager.createOfflineSample(
        sample,
        project_id
      );
      await loadProjects();
    };

    const updateOfflineSample = async (
      data: UpdateSampleRequest,
      project_id: number
    ) => {
      await offlineLocalForageStorageManager.updateOfflineSample(
        data,
        project_id
      );
      await loadProjects();
    };

    const deleteOfflineSample = async (
      id: number | string,
      project_id: number
    ) => {
      await offlineLocalForageStorageManager.deleteOfflineSample(
        id,
        project_id
      );
      await loadProjects();
    };

    const updateOfflineSampleValues = async (
      sample_id: number | string,
      values: any[],
      project_id: number
    ) => {
      await offlineLocalForageStorageManager.updateOfflineSampleValues(
        sample_id,
        values,
        project_id
      );
      await loadProjects();
    };

    return {
      isLoading,
      isDownloading,
      offlineProjects,

      loadProjects,
      setProjects,
      getProjectById,

      createOfflineProject,
      deleteOfflineProject,
      updateOfflineProject,

      createOfflineSample,
      updateOfflineSample,
      deleteOfflineSample,
      updateOfflineSampleValues,
    };
  }
);
