import { defineStore } from 'pinia';
import { ref } from 'vue';
import { Figure } from '../maps';
import { useToastStore } from './toasts';
import useApi from '../api';

export const useFigureStore = defineStore('figures', () => {
  const toastStore = useToastStore();
  const api = useApi();

  let loadedProjectId: null | number = null;
  const projectFigures = ref<Figure[]>([]);
  const lastFigureId = ref<number | null>(null);

  return {
    projectFigures,
    fetchProjectFigures: async function (
      projectId: number,
      params?: any
    ): Promise<{
      figures?: Figure[];
      last_figure_id?: number | null;
    }> {
      loadedProjectId = projectId;

      try {
        const { data } = await api.get(
          `/figure8/figure/list?project_id=${projectId}`,
          { params }
        );

        if (projectId !== loadedProjectId) {
          console.warn(
            'Duplicate request for project figures, not keeping outdated in store.'
          );
          return data;
        }

        if (params) {
          // Dont save if bespoke params were passed in
          return data;
        }

        projectFigures.value = data.figures || [];
        lastFigureId.value = data.last_figure_id;
      } catch (err) {
        toastStore.error('Unable to load figures for this project.');
        throw err;
      }

      return {
        figures: projectFigures.value,
        last_figure_id: lastFigureId.value,
      };
    },
  };
});
