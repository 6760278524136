import auth from "@component-library/auth";

export const get = async (
  url: string,
  params: Record<string, any> = {},
  headers: Record<string, any> = {},
  additionalOptions: Record<string, any> = {}
) => {
  // append parameters to url as query string
  if (params && Object.keys(params).length > 0) {
    const queryString = new URLSearchParams(params).toString();
    url += '?' + queryString;
  }

  const response = await fetch(url, {
    headers: _getHeaders(headers),
    ...additionalOptions,
  });

  return response;
};

export const getJSON = async (
  url: string,
  {
    params = {},
    headers = {},
  }: {
    params?: Record<string, any>;
    headers?: Record<string, any>;
  } = {}
) => {
  const response = await get(url, params, headers);

  const data = await response.json();

  return { data };
};

export const post = async (
  url: string,
  body: FormData,
  headers: Record<any, any> = {}
) => {
  const response = await fetch(url, {
    method: 'POST',
    headers: _getHeaders(headers),
    body,
  });

  return await response.json();
};

const _getHeaders = (headers: Record<any, any> = {}) => {
  const bearerToken = auth.getToken();
  const defaultHeaders = {
    Authorization: `Bearer ${bearerToken}`,
  };

  return {
    ...defaultHeaders,
    ...headers,
  };
};
