<script lang="ts" setup>
import InputTag from '@component-library/components/InputTag.vue';
import ImportExcelColumnModal from './ImportExcelColumnModal.vue';
import InfoButton from '@component-library/components/InfoButton.vue';
import NotifyModal from '@component-library/components/NotifyModal.vue';
import apis from '../../../apis';
import MarkerPicker from '@component-library/widgets/marker-picker/index.vue';
import Slider from '@component-library/components/Slider.vue';
import ColorChooser from '@component-library/components/mapping/ColorChooser.vue';
import makeId from '@component-library/local-id.mjs';
import Spinner from '@component-library/components/Spinner.vue';
import InputCheckbox from '@component-library/components/InputCheckbox.vue';
import { computed, ref, watch } from 'vue';
import { App, GatherFieldOptions, InputValue } from '@component-library/gather';
import {
  OPTION_TYPE_NUMBER,
  OPTION_TYPE_OPTIONS,
  OPTION_TYPE_TEXT,
} from '@component-library/business-model/dropdown';
import { checkIsOptionDuplicate } from '@component-library/business-logic/dropdown';
import { useToastStore } from '@component-library/store/toasts';

const toastStore = useToastStore();
const props = withDefaults(
  defineProps<{
    value: InputValue;
    options: GatherFieldOptions;
    tab: App;
    optionTypeVisible?: boolean;
    multipleVisible?: boolean;
    addOptionsVisible?: boolean;
    readOnlyVisible?: boolean;
  }>(),
  {
    optionTypeVisible: true,
    multipleVisible: true,
    addOptionsVisible: true,
    readOnlyVisible: true,
  }
);

const emit = defineEmits<{
  (event: 'updateOptions', value: GatherFieldOptions): void;
  (event: 'selectOption', value: string | undefined): void;
  (event: 'blur'): void;
}>();

const optionTypeSelectId = ref(makeId());
const optionTypeOptions = ref(OPTION_TYPE_OPTIONS);
const isSelectingNumberNotifyModalVisible = ref(false);
const selectedOption = ref<string>();
const optionEditorContent = ref<string>();
const isSubmittingOptionChange = ref(false);
const isImportModalVisible = ref(false);

const nonNumberOptions = computed(() => {
  const { options = [] } = props.options;
  return options.filter((item) => !checkIsNumberOption(item));
});
const selectingNumberNotice = computed(() => {
  return `Selecting the Number option type will remove all existing non-number options: ${nonNumberOptions.value.join(
    ', '
  )}. Are you sure you would like to proceed?`;
});
const option_type = computed({
  get() {
    const { option_type, options = [] } = props.options;
    if (option_type) {
      return option_type;
    }

    return options.length && !nonNumberOptions.value.length
      ? OPTION_TYPE_NUMBER
      : OPTION_TYPE_TEXT;
  },
  set(value) {
    if (value === OPTION_TYPE_NUMBER && nonNumberOptions.value.length) {
      isSelectingNumberNotifyModalVisible.value = true;
      return;
    }

    emit('updateOptions', {
      ...props.options,
      option_type: value,
    });
  },
});

const has_multiple = computed({
  get() {
    return props.options?.has_multiple || false;
  },
  set(value) {
    const { defaults = [] } = props.options;
    const nextDefaults = defaults.map((aDefault) => {
      if ('default' in aDefault) {
        if (!value && Array.isArray(aDefault.default)) {
          return {
            ...aDefault,
            default: aDefault.default ? [aDefault.default[0]] : [],
          };
        }
        if (value && !Array.isArray(aDefault.default)) {
          return {
            ...aDefault,
            default: aDefault.default ? [aDefault.default] : [],
          };
        }
      }
      return aDefault;
    });

    emit('updateOptions', {
      ...props.options,
      has_multiple: value,
      defaults: nextDefaults,
    });
  },
});

const is_add_enabled = computed({
  get() {
    return props.options?.is_add_enabled || false;
  },
  set(value) {
    emit('updateOptions', {
      ...props.options,
      is_add_enabled: value,
    });
  },
});

const is_readonly = computed({
  get() {
    return props.options?.is_readonly || false;
  },
  set(value) {
    emit('updateOptions', {
      ...props.options,
      is_readonly: value,
      is_add_enabled: value ? undefined : props.options.is_add_enabled,
      has_multiple: value ? undefined : props.options.has_multiple,
      should_clear_selection_on_invisible: value
        ? undefined
        : props.options.should_clear_selection_on_invisible,
    });
  },
});

const should_clear_selection_on_invisible = computed({
  get() {
    return props.options?.should_clear_selection_on_invisible || false;
  },
  set(value) {
    emit('updateOptions', {
      ...props.options,
      should_clear_selection_on_invisible: value,
    });
  },
});

const display_as_input = computed({
  get() {
    return props.options?.display_as_input || false;
  },
  set(value) {
    emit('updateOptions', {
      ...props.options,
      display_as_input: value,
    });
  },
});

const isOptionEditorContentChanged = computed(() => {
  return selectedOption.value !== optionEditorContent.value;
});
const nodeIcons = computed(() => {
  return props.options?.node_icons || {};
});

watch(selectedOption, (value) => {
  optionEditorContent.value = value;
  emit('selectOption', value);
});

function checkIsNumberOption(option) {
  return !Number.isNaN(parseFloat(option));
}

function setSelectedOption(value) {
  selectedOption.value = value;
}

function openImportModal() {
  isImportModalVisible.value = true;
}

function closeImportModal() {
  isImportModalVisible.value = false;
}

function handleInputTagInput(options) {
  const { option_type } = props.options;
  if (option_type === OPTION_TYPE_NUMBER) {
    options = options.filter((item) => checkIsNumberOption(item));
  }

  emit('updateOptions', {
    ...props.options,
    options,
  });
}

function handleSelectNumber() {
  const { options = [] } = props.options;
  const nextOptions = options.filter((item) => checkIsNumberOption(item));
  emit('updateOptions', {
    ...props.options,
    options: nextOptions,
    option_type: OPTION_TYPE_NUMBER,
  });
  isSelectingNumberNotifyModalVisible.value = false;
}

function handleClickOption(option) {
  setSelectedOption(option);
}

function handleRemoveSelectedOption() {
  const { options = [] } = props.options;
  const nextOptions = options.filter(
    (option) => option !== selectedOption.value
  );
  emit('updateOptions', {
    ...props.options,
    options: nextOptions,
  });
  selectedOption.value = undefined;
}

function handleClickCancelOptionChangeButton() {
  optionEditorContent.value = selectedOption.value;
}

async function handleClickSubmitOptionChangeButton() {
  if (!selectedOption.value || !optionEditorContent.value) {
    toastStore.unexpected();
    throw new Error('No option selected');
  }
  isSubmittingOptionChange.value = true;
  try {
    // Update the node_icons if the option is used as a node icon.
    await apis.dropdown.updateDropdownOption(
      props.value.id,
      selectedOption.value,
      optionEditorContent.value
    );

    const updatedNodeIcons = { ...props.options.node_icons };
    const previousIconId = updatedNodeIcons[selectedOption.value] || null;
    if (previousIconId !== null) {
      delete updatedNodeIcons[selectedOption.value];
      updatedNodeIcons[optionEditorContent.value] = previousIconId;
      emit('updateOptions', {
        ...props.options,
        node_icons: updatedNodeIcons,
      });
    }

    if (props.options.options) {
      const oldValueIndex =
        props.options.options?.indexOf(selectedOption.value) ?? -1;
      if (oldValueIndex !== -1) {
        props.options.options[oldValueIndex] = optionEditorContent.value;
      }
    } else {
      throw new Error('Options are not defined');
    }

    selectedOption.value = optionEditorContent.value;
  } catch (e) {
    toastStore.error('Something went wrong, please try it again later.');
  } finally {
    isSubmittingOptionChange.value = false;
  }
}

function handleImportClick() {
  openImportModal();
}

function handleImport(options) {
  const { option_type } = props.options;
  if (option_type === OPTION_TYPE_NUMBER) {
    options = options.filter((item) => checkIsNumberOption(item));
  }

  const { options: existingOptions = [] } = props.options;
  options = options.filter(
    (item) => !checkIsOptionDuplicate(existingOptions, item)
  );
  options = [...existingOptions, ...options];

  emit('updateOptions', {
    ...props.options,
    options,
  });

  closeImportModal();
}

function handleImportModalClose() {
  closeImportModal();
}

function displayAs(option) {
  display_as_input.value = option === 'dropdown';
}

function onNodeRemove() {
  const oldOptions = {
    ...props.options.node_icons,
  };

  if (selectedOption.value) {
    delete oldOptions[selectedOption.value];
  }

  emit('updateOptions', {
    ...props.options,
    node_icons: oldOptions,
  });
}

function onNodeSelect(data) {
  if (!data) {
    return;
  }
  if (!selectedOption.value) {
    throw new Error('No option selected');
  }

  emit('updateOptions', {
    ...props.options,
    node_icons: {
      ...(props.options.node_icons || {}),
      [selectedOption.value]: {
        icon_id: data.id,
        size:
          (props.options.node_icons
            ? props.options.node_icons[selectedOption.value]?.size
            : null) || 16,
        color:
          (props.options.node_icons
            ? props.options.node_icons[selectedOption.value]?.color
            : null) || '#000000',
      },
    },
  });
}

function setNodeIconAttribute(key, value) {
  if (!selectedOption.value) {
    throw new Error('No option selected');
  }
  const icons = props.options.node_icons || {};
  emit('updateOptions', {
    ...props.options,
    node_icons: {
      ...icons,
      [selectedOption.value]: {
        ...(icons[selectedOption.value] || {}),
        [key]: value,
      },
    },
  });
}
</script>

<template>
  <div>
    <div class="mb-2">
      <label class="form-label w-100">Options</label>
      <InputTag
        :value="options.options"
        :hasInlineDeletion="false"
        :has-import="true"
        :selected-item="selectedOption"
        @input="handleInputTagInput"
        @clickOption="handleClickOption"
        @useClick="handleImportClick"
      >
        <template #extensionContainer>
          <slot
            name="extensionContainer"
            :setSelectedOption="setSelectedOption"
          ></slot>
        </template>
      </InputTag>
      <small class="d-block mt-1 text-muted">
        Press the <b>Enter</b> key after the phrase to add it as an option
      </small>
    </div>

    <!--Option Editor-->
    <div v-if="selectedOption" class="input-group mb-2">
      <input
        type="text"
        class="form-control"
        placeholder="Click an option above"
        v-model.trim="optionEditorContent"
      />
      <button
        v-if="isOptionEditorContentChanged && !isSubmittingOptionChange"
        type="button"
        class="btn btn-outline-secondary"
        @click="handleClickCancelOptionChangeButton"
      >
        <i class="fas fa-times"></i>
      </button>
      <button
        v-if="isOptionEditorContentChanged"
        type="button"
        class="btn btn-outline-primary"
        :disabled="isSubmittingOptionChange"
        @click="handleClickSubmitOptionChangeButton"
      >
        <i v-if="!isSubmittingOptionChange" class="fas fa-check"></i>
        <Spinner small v-else />
      </button>
      <button
        v-if="!isOptionEditorContentChanged"
        type="button"
        class="btn btn-outline-danger"
        @click="handleRemoveSelectedOption"
      >
        <i class="fas fa-trash-alt"></i>
      </button>
    </div>

    <template v-if="selectedOption && tab.drawing_type === 'polyline'">
      <label class="form-label me-2">
        Node Marker
        <a
          v-if="nodeIcons[selectedOption]"
          href="#"
          @click.prevent="onNodeRemove"
        >
          <i class="fal fa-trash-alt" />
        </a>
      </label>

      <MarkerPicker
        class="small-palette mb-2"
        :appId="2"
        :selectedMarkerId="nodeIcons[selectedOption]?.icon_id || null"
        @markerSelect="onNodeSelect"
      />

      <Slider
        v-if="nodeIcons[selectedOption]"
        name="arrowheadsize"
        label="Node Marker Size"
        :value="nodeIcons[selectedOption]?.size || 16"
        units="pt"
        :min="4"
        :max="32"
        :step="1"
        @input="(value) => setNodeIconAttribute('size', value)"
      />

      <ColorChooser
        v-if="nodeIcons[selectedOption]"
        :value="nodeIcons[selectedOption]?.color || '#000000'"
        @input="(value) => setNodeIconAttribute('color', value)"
        class="mb-2"
      />
    </template>

    <div v-if="optionTypeVisible" class="mb-2">
      <label class="form-label me-2" :for="optionTypeSelectId">
        Option Type
      </label>
      <InfoButton
        class="ms-2"
        info="This setting determines how the options are handled when they are used in an expression.<br/><br/> WARNING: Selecting the <b>Number</b> option type will remove all existing non-number options."
      />
      <select
        class="form-control"
        :id="optionTypeSelectId"
        v-model.number="option_type"
      >
        <option
          v-for="item in Object.keys(optionTypeOptions)"
          :key="`optionType-${item}`"
          :value="item"
        >
          {{ optionTypeOptions[item].text }}
        </option>
      </select>
    </div>

    <div class="mb-2">
      <label class="form-label me-2"> Display as </label>
      <div class="btn-group btn-group-sm d-block">
        <button
          class="btn btn-dark w-50"
          :class="{
            active: !display_as_input,
          }"
          @click="displayAs('checkbox')"
        >
          Checkbox
        </button>
        <button
          class="btn btn-dark w-50"
          :class="{
            active: display_as_input,
          }"
          @click="displayAs('dropdown')"
        >
          Dropdown
        </button>
      </div>
    </div>

    <InputCheckbox
      v-if="multipleVisible && !is_readonly"
      label="Do you want users to select multiple options?"
      v-model="has_multiple"
    />

    <InputCheckbox
      v-if="addOptionsVisible && !is_readonly"
      label="Do you want users to add options?"
      v-model="is_add_enabled"
    />

    <InputCheckbox
      v-if="!is_readonly"
      label="Clear selection when it is not visible"
      v-model="should_clear_selection_on_invisible"
    />

    <NotifyModal
      v-if="isSelectingNumberNotifyModalVisible"
      @close="() => (isSelectingNumberNotifyModalVisible = false)"
      @submit="handleSelectNumber"
      :headerMessage="selectingNumberNotice"
      :isDelete="true"
    />

    <slot
      name="importModal"
      :isImportModalVisible="isImportModalVisible"
      :closeImportModal="closeImportModal"
    >
      <ImportExcelColumnModal
        v-if="isImportModalVisible"
        @use="handleImport"
        @close="handleImportModalClose"
      />
    </slot>
  </div>
</template>
