<script setup lang="ts">
import { computed, ref } from 'vue';
import InfoButton from '@component-library/components/InfoButton.vue';
import InputTag from '@component-library/components/InputTag.vue';
import ImportExcelColumnModal from './ImportExcelColumnModal.vue';

const props = defineProps<{
  value: [];
  checkIsSubFolderRemovable: (index: number) => boolean;
}>();

const emit = defineEmits(['input']);

const isImportModalVisible = ref(false);

const options = computed<string[]>({
  get() {
    return props.value || [];
  },
  set(value: string[]) {
    emit('input', value);
  },
});

function handleImportClick() {
  isImportModalVisible.value = true;
}

function handleImport(importedOptions: string[]) {
  const newOptions = importedOptions.filter(
    (io) => !options.value.includes(io)
  );
  options.value = [...options.value, ...newOptions];
  handleImportModalClose();
}

function handleImportModalClose() {
  isImportModalVisible.value = false;
}
</script>

<template>
  <div>
    <label class="form-label">Sub Folders </label>
    <InfoButton
      class="ms-2"
      info="Create sub folders to your items to make them easier to order on the map"
    />
    <InputTag
      v-model="options"
      :checkIsTagRemovable="checkIsSubFolderRemovable"
      :has-import="true"
      @useClick="handleImportClick"
    />
    <small class="d-block mt-1 text-muted">
      Press the <b>Enter</b> key after the phrase to add it as a sub-folder
    </small>

    <ImportExcelColumnModal
      v-if="isImportModalVisible"
      @use="handleImport"
      @close="handleImportModalClose"
    />
  </div>
</template>
