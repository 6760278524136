import { ref } from 'vue';
import axios from 'axios';
import { OfflineProject, OfflineSample } from '@component-library/offline-data';
import { InputValue } from '@component-library/business-logic/input-value';
import { captureException } from '@component-library/sentry';

export default function useSampleUploadManager(project: OfflineProject) {
  const uploadProgress = ref<any[]>([]);

  const updateOnlineSample = async (sample: OfflineSample) => {
    const offlineSample = project.samples.find(
      (item) => item.id === sample.id
    )!;

    if (offlineSample !== sample) {
      // The offline item has been removed from the cache.
      throw `The offline item is missing.`;
    }

    const uploadedAssetValues: InputValue[] = [];

    for (const item of offlineSample.values) {
      if (item.value instanceof Blob) {
        try {
          const asset = await _uploadAsset(item.value, item);
          uploadedAssetValues.push(asset);
        } catch (e) {
          captureException('Failed to upload offline single asset', {
            extra: {
              e,
            },
          });

          throw 'Failed to upload single asset.';
        }
      } else {
        if (Array.isArray(item.value) && item.value.length) {
          for (let i = 0; i < item.value.length; i++) {
            const asset = item.value[i];
            const { blob, name } = asset;
            if (blob instanceof Blob) {
              try {
                await _uploadAsset(blob, item, i, name);
              } catch (e) {
                captureException('Failed to upload offline multi asset', {
                  extra: {
                    e,
                  },
                });

                throw 'Failed to upload multi asset.';
              }
            }
          }
        }
        uploadedAssetValues.push(item);
      }
    }

    const inputValues = await Promise.all(uploadedAssetValues);
    const sampleData: SampleData = {
      ...offlineSample,
      sample_id: offlineSample.id,
      offline_user_id: null,
    };

    // The sample is a new one created in the offline mode.
    if (sampleData.sample_id && typeof sampleData.sample_id !== 'number') {
      delete sampleData.sample_id;
    }

    try {
      await saveSampleAndValues(sampleData, inputValues);
    } catch (e) {
      captureException('Failed to upload item & values', {
        extra: {
          e,
        },
      });

      throw 'Failed to update item & values';
    }
  };

  type SampleData = {
    template_tab_id: number | null;
    sample_id?: string | number;
    custom_title: string;
    latitude: number;
    longitude: number;
    icon_opacity?: number | null;
    icon_opacity_override?: number | null;
    area_geojson: any;
    sub_folder?: null | any[];
    offline_user_id: number | null;
  };

  const saveSampleAndValues = async (
    sampleData: SampleData,
    inputValues: InputValue[]
  ) => {
    const {
      template_tab_id,
      sample_id: sampleId,
      custom_title,
      latitude,
      longitude,
      icon_opacity,
      icon_opacity_override,
      area_geojson,
      sub_folder,
    } = sampleData;

    const isNonSpatial = _checkIsNonSpatial(sampleData);

    const {
      data: {
        sample,
        input_values: _input_values,
        area_figure_layer,
        samples_count,
      },
    } = await axios.post('/api/project/sample/update', {
      template_tab_id,
      sample_id: sampleId,
      custom_title: custom_title || 'Untitled Sample',
      latitude: isNonSpatial ? null : latitude,
      longitude: isNonSpatial ? null : longitude,
      icon_opacity,
      icon_opacity_override,
      area_geojson,
      input_values: inputValues,
      sub_folder,
      project_id: project.project_id,
    });

    return {
      sample,
      input_values: _input_values,
      area_figure_layer,
      samples_count,
    };
  };

  const _uploadAsset = async (
    blob: Blob,
    inputValue: InputValue,
    index: number | null = null,
    name: string | null = null
  ) => {
    let isJSON = false;

    // ! Only a check, will fail if not JSON but needs to continue
    try {
      if (JSON.parse(inputValue.value2 as string)) {
        isJSON = true;
      }
    } catch (e) { }

    name = isJSON ? 'drawing.png' : name || (inputValue.value2 as string);

    uploadProgress.value.push({
      inputValue,
      name,
      progress: 0,
      error: {
        status: null,
        message: null,
        errors: [],
      },
    });

    const progressIndex = uploadProgress.value.length - 1;
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (e) => {
        if (e.lengthComputable && uploadProgress[progressIndex]) {
          uploadProgress[progressIndex].progress = Math.round(
            (e.loaded / e.total) * 100
          );
        }
      },
    };

    const data = new FormData();

    data.append('file', blob);
    data.append('project_id', project.project_id.toString());

    try {
      const {
        data: { src },
      } = await axios.post(`/api/project/asset`, data, config);

      if (!index && index !== 0) {
        inputValue.value = src;
      } else {
        inputValue.value![index].src = src;
        inputValue.value![index].blob = null;
      }

      return inputValue;
    } catch (error: any) {
      if (error.response) {
        const {
          status,
          statusText,
          data: { message },
        } = error.response;

        if (status) {
          uploadProgress.value[
            progressIndex
          ].error.status = `${status} - ${statusText}`;
        }

        if (message) {
          uploadProgress.value[progressIndex].error.message = message;
        }
      } else {
        uploadProgress.value[progressIndex].error.status = error;
      }

      throw error;
    }
  };

  const _checkIsNonSpatial = (sample: SampleData) => {
    return !sample.area_geojson && !sample.longitude && !sample.latitude;
  };

  const bringSampleOnline = async (id: number | string) => {
    await axios.patch(`/api/offline/upload/${id}`);
  };

  return {
    updateOnlineSample,
    saveSampleAndValues,
    bringSampleOnline,
  };
}
